
import { defineComponent } from 'vue'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  setup () {
    return {
      link: {
        name: PAGE_NAME.HOME
      }
    }
  }
})
